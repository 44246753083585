import Vue from "vue"
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import aws_exports from "./aws-config/aws-exports.js"
import ModalContainer from "./plugins/ModalContainer"

import VueApollo from "vue-apollo"
import ContentfulGQL from "@/integration/ContentfulGQL"

import VueCookies from "vue-cookies"
import ContentCache from "@/integration/ContentCache"

require("intersection-observer")
require("loading-attribute-polyfill")

import VueApp from "./App.vue"
import router from "./router"
import store from "./store/index"
import vSelect from "vue-select"
import { FocusTrap } from "focus-trap-vue"
import VueScrollTo from "vue-scrollto"
import VueAccessibleSelect from "vue-accessible-select"

import * as Sentry from "@sentry/vue"

// UNHEAD - meta handler //
import { createHead } from "@unhead/vue"
import { UnheadPlugin } from "@unhead/vue/dist/vue2.mjs"

// TODO: Remove this modal component after migrating the booking slot selector
import VueAccessibleModal from "vue-accessible-modal"

import { prefixedSlug } from "@/utils"

import "./styles/main.scss"

// CONTENTFUL COMPONENTS
const FaqEntry = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/FaqEntry.vue"
  )
const Gallery = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/gallery/Gallery.vue"
  )
const HowToGuide = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/howTo/HowToGuide.vue"
  )
const MediaItem = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/MediaItem.vue"
  )
const ProductCategory = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/ProductCategory.vue"
  )
const RichTextSection = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/RichTextSection/RichTextSection.vue"
  )
const Salon = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/salons/Salon.vue"
  )
const SalonData = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/salons/SalonData.vue"
  )
const Section = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/section/Section.vue"
  )
const SocialMediaPost = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/socialMediaPost/socialMediaPost.vue"
  )
const uiBanner = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/uiBanner/uiBanner.vue"
  )
const uiHeader = () =>
  import(
    /* webpackChunkName: "[request]" */ "./contentfulComponents/uiHeader/uiHeader.vue"
  )

import BasicButton from "./components/buttons/BasicButton"
import EcomButton from "./components/buttons/EcomButton"
import LinkTag from "./components/buttons/LinkTag"
import FormWrapper from "./components/form/FormWrapper.vue"
import FormInput from "./components/form/FormInput.vue"
import ImageComponent from "./components/ImageComponent"
import ReviewStars from "./components/reviews/ReviewStars"
import SvgSprite from "./components/SvgSprite"

//CONTENTFUL MIXINS
import uiElement from "@/contentfulComponents/mixins/uiElement"
import uiTextContent from "@/contentfulComponents/mixins/uiTextContent"
Vue.mixin(uiElement)
Vue.mixin(uiTextContent)

// EXTERNAL LIBARIES
Vue.use(VueAccessibleSelect)
Vue.use(ModalContainer)
Vue.use(VueAccessibleModal)
Vue.component("v-select", vSelect)
Vue.component("focus-trap", FocusTrap)
Vue.use(VueScrollTo)
Vue.use(VueApollo)
Vue.use(VueCookies)

// UNHEAD
const unhead = createHead()
Vue.use(UnheadPlugin)

// CONTENTFUL UI COMPONENTS
Vue.component("EditorialSection", Section)
Vue.component("Gallery", Gallery)
Vue.component("ProductCategory", ProductCategory)
Vue.component("RichTextSection", RichTextSection)
Vue.component("SocialMediaPost", SocialMediaPost)
Vue.component("BasicButton", BasicButton)
Vue.component("EcomButton", EcomButton)
Vue.component("LinkTag", LinkTag)
Vue.component("FormWrapper", FormWrapper)
Vue.component("FormInput", FormInput)
Vue.component("BleachImage", ImageComponent)
Vue.component("SvgSprite", SvgSprite)
Vue.component("Salon", Salon)
Vue.component("ReviewStars", ReviewStars)
Vue.component("MediaItem", MediaItem)
Vue.component("UiBanner", uiBanner)
Vue.component("UiHeader", uiHeader)
Vue.component("HowToGuide", HowToGuide)
Vue.component("FaqEntry", FaqEntry)
Vue.component("SalonData", SalonData)

// AWS
Vue.use(Amplify, Auth)
Amplify.configure(aws_exports)

Vue.config.productionTip = false

// CONTENT CACHE INIT
ContentCache.init()

// LOGGER MIXIN
import BleachLogger from "@/helpers/BleachLogger.js"

BleachLogger.level = BleachLogger[process.env.VUE_APP_LOG_LEVEL || "WARN"]

Vue.mixin({
  data: () => ({
    debug: BleachLogger
  }),

  methods: {
    fullSlugFromEntry(pageEntry) {
      if (!pageEntry) return undefined
      return prefixedSlug(pageEntry.slugPrefix, pageEntry.slug)
    }
  }
})

const apolloProvider = new VueApollo({
  defaultClient: ContentfulGQL.apolloClient,
  defaultOptions: {
    $query: {
      errorPolicy: "all"
    }
  }
})

export const Bleach = new Vue({
  router,
  store: store,
  render: h => h(VueApp),
  apolloProvider,
  unhead,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app")

// SENTRY setup
Sentry.init({
  Vue,
  dsn:
    "https://2d011f92a3b464c8e6eabaeb04ea0354@o4506559242436608.ingest.us.sentry.io/4507073944420352",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  enabled: process.env.VUE_APP_TRACKING_ENV == "development",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost" /* , /^https:\/\/bleachlondon\.com/ */],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
